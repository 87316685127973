<template>
  <div class="contactUs">
    <div class="title">
      <div class="title-img">
        <img src="~assets/images/icon/start.png" alt="" />
      </div>
      <section>CONTACT US</section>
    </div>
    <div class="coordinate">
      <div class="coordinate-title">Our Location</div>
      <div class="coordinate-block d-lg-flex d-md-flex">
        <div class="map col-lg-6 col-md-6">
          <img :src="getInfo.addressimg" alt="" />
        </div>
        <div class="info col-lg-5 col-md-5">
          <div class="info-title">{{ getInfo.copyright }}</div>
          <p>{{ getInfo.address }}</p>
          <p>
            Toll Free:
            <a :href="'tel:' + getInfo.tollfree" style="font-weight: 600"
              >{{ getInfo.tollfree }}
            </a>
          </p>
          <p>Fax: {{ getInfo.fax }}</p>
          <p>Email:{{ getInfo.email }}</p>
        </div>
      </div>
    </div>
    <div class="form">
      <div class="coordinate-block d-lg-flex d-md-flex">
        <div class="form-left col-lg-6 col-md-6">
          <div class="single">
            <section>Name*</section>
            <input
              type="text"
              placeholder="Name"
              v-model="infomation.name"
              :class="judge[0].err ? 'err-input' : 'right-input'"
              @focus="fun1"
            />
            <p v-if="judge[0].err">{{ judge[0].msg }}</p>
          </div>
          <div class="single">
            <section>Email*</section>
            <input
              type="text"
              placeholder="Email"
              v-model="infomation.email"
              :class="judge[1].err ? 'err-input' : 'right-input'"
              @focus="fun2"
            />
            <p v-if="judge[1].err">{{ judge[1].msg }}</p>
          </div>
          <div class="single">
            <section>Subject*</section>
            <input
              type="text"
              placeholder="Subject"
              v-model="infomation.subject"
              :class="judge[2].err ? 'err-input' : 'right-input'"
              @focus="fun3"
            />
            <p v-if="judge[2].err">{{ judge[2].msg }}</p>
          </div>
        </div>

        <div class="single col-lg-6 col-md-6">
          <section>Message*</section>
          <textarea
            :class="judge[3].err ? 'err-text ' : 'right-text'"
            placeholder="Message"
            v-model="infomation.content"
            @focus="fun4"
          ></textarea>
          <p v-if="judge[3].err">{{ judge[3].msg }}</p>
        </div>
      </div>
      <div class="submit" @click="submit">submit</div>
    </div>

    <div class="hint">
      Sign up for our News Letter where you will receive updates on special
      promotions, discounts, as well as exciting product updates. Enter your
      email address above and click the "receive email updates checkbox".
    </div>
  </div>
</template>
<script>
import { GetInfo, sendMessage } from "network/contactUs";
export default {
  data() {
    return {
      getInfo: {},
      infomation: {
        name: "",
        email: "",
        subject: "",
        content: "",
      },
      judge: [
        {
          err: false,
          msg: "",
        },
        {
          err: false,
          msg: "",
        },
        {
          err: false,
          msg: "",
        },
        {
          err: false,
          msg: "",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.GetInfo();
    if (this.$route.params.email) {
      this.infomation.email = this.$route.params.email;
    } else {
      return;
    }
  },
  methods: {
    submit() {
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (this.infomation.name == "") {
        this.judge[0].err = true;
        this.judge[0].msg = "This cannot be empty";
        return;
      }
      if (this.infomation.email == "") {
        this.judge[1].err = true;
        this.judge[1].msg = "This cannot be empty";
        return;
      } else if (!verify.test(this.infomation.email)) {
        this.judge[1].err = true;
        this.judge[1].msg = "Please enter a valid email address";
        return;
      }
      if (this.infomation.subject == "") {
        this.judge[2].err = true;
        this.judge[2].msg = "This cannot be empty";
        return;
      }
      if (this.infomation.content == "") {
        this.judge[3].err = true;
        this.judge[3].msg = "This cannot be empty";
        return;
      }

      sendMessage(
        this.infomation.name,
        this.infomation.email,
        this.infomation.subject,
        this.infomation.content
      ).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.infomation.name = "";
          this.infomation.email = "";
          this.infomation.subject = "";
          this.infomation.content = "";
        } else {
          return;
        }
      });

      // this.$http
      //   .post(
      //     this.$store.state.https + "/api/contact/sendMessage",
      //     this.infomation,
      //     {
      //       emulateJSON: true,
      //     }
      //   )
      //   .then((res) => {
      //     if (res.body.code == 1) {
      //       this.$notify({
      //         message: "success",
      //         type: "success",
      //         duration: 3000,
      //         position: "top-left",
      //         showClose: false,
      //         offset: 300,
      //       });
      //       this.infomation.name = "";
      //       this.infomation.email = "";
      //       this.infomation.subject = "";
      //       this.infomation.content = "";
      //     } else {
      //       return;
      //     }
      //   });
    },
    GetInfo() {
      GetInfo().then((res) => {
        this.getInfo = res.data.data;
      });
    },
    fun1() {
      this.judge[0].err = false;
    },
    fun2() {
      this.judge[1].err = false;
    },
    fun3() {
      this.judge[2].err = false;
    },
    fun4() {
      this.judge[3].err = false;
    },
  },
};
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}
.contactUs {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.title {
  padding: 0;
  background: #ff7f00;
  display: flex;
  font-size: 0.75rem;
  color: #fff;
  align-items: center;
}
.title-img {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}
.title-img img {
  width: 0.5rem;
  height: 0.5rem;
}
.title section {
  padding: 0.2rem 0;
}
.coordinate {
  padding: 0.5rem;
}
.coordinate-block {
  justify-content: space-between;
}
.coordinate-title {
  font-size: 0.5rem;
  color: #666666;
  font-weight: 600;
  padding-bottom: 0.5rem;
}
.map {
  margin: 0;
  padding: 0;
}
.map img {
  width: 100%;
}
.info {
  margin: 0;
  padding: 0;
}
.info p {
  font-size: 0.4rem;
  color: #666666;
  margin-top: 0.5rem;
}
.info-title {
  font-size: 0.5rem;
  margin-top: 1rem;
  color: #333333;
  font-weight: 600;
}
.single {
  margin-top: 0.75rem;
}
.right-input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.err-input {
  width: 100%;
  border: 1px solid #f56c6c;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.single section {
  color: #666666;
  font-size: 0.4rem;
  font-weight: 600;
}
.single p {
  font-size: 0.4rem;
  color: #f56c6c;
}
.right-text {
  margin-top: 0.5rem;
  resize: none;
  width: 100%;
  height: 4rem;
  border: 1px solid #ededed;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.err-text {
  margin-top: 0.5rem;
  resize: none;
  width: 100%;
  height: 4rem;
  border: 1px solid #f56c6c;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.submit {
  font-size: 0.4rem;
  color: #fff;
  background: #4d4d4d;
  padding: 0.3rem 0.75rem;
  border-radius: 0.25rem;
  margin: 1rem auto;
  text-align: center;
  max-width: 5rem;
  cursor: pointer;
}
.hint {
  font-size: 0.4rem;
  color: #666666;
  border: 1px solid #ededed;
  margin: 0.5rem;
  padding: 0.5rem;
  line-height: 1rem;
  border-radius: 0.25rem;
}
</style>
